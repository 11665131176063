
import FileDownloadTemplates from "~/graphql/Download/FolderTemplates.gql";
import { useUserStore } from "~/composables/stores/user";

export default {
  apollo: {
    fileDownloadTemplates: {
      query: FileDownloadTemplates,
      variables() {
        return {
          filter: {
            includeStaticTemplates: true,
          },
        };
      },
      update(data) {
        return data.fileDownloadTemplates;
      },
    },
  },

  props: {
    propertyType: {
      type: String,
      required: true,
    },

    showError: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selectedTemplate: null,
      reciever: "me",
      getCopy: false,
      message: "",
      email: this.$t("DOX_RIGHT_PANEL_INPUT_EXAMPLE"),
      emailValid: true,
    };
  },

  computed: {
    user() {
      return useUserStore().user;
    },

    loading() {
      return this.$apollo.queries.fileDownloadTemplates.loading;
    },

    useFolderTemplates() {
      return this.$isFeatureSupported("folderTemplates");
    },

    folderTemplatesForPropertyType() {
      return this.fileDownloadTemplates?.filter((t) => t.propertyType === this.propertyType);
    },
  },

  watch: {
    folderTemplatesForPropertyType(val) {
      if (val && val.length > 0) {
        this.selectedTemplate = val[0];
      }
    },
  },

  methods: {
    validateEmail(s) {
      if (s.length > 0) {
        return /^[\w-+.]+@([\w-+]+\.)+[\w-]{2,4}/.test(s);
      }

      return true;
    },
  },
};
